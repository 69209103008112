import React, { useState } from 'react';
import { useMediaQuery } from '../../../utils/responsive-query';
import { NavLink, useLocation } from 'react-router-dom';
import NavDropDown from '../../molecules/navbar-dropdown';
import { ReactComponent as ArrowUp } from '../../../assets/icons/mobile-arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/mobile-arrow-down.svg';
// import Banner from '../banner/banner';
// import BannerPins from '../banner/banner-pins';

export default function Navbar() {
	const location = useLocation();
	let isPageWide = useMediaQuery('(min-width: 1024px)');
	let isMobile = useMediaQuery('(max-width: 1023px)');
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [priceDownOpen, setPriceDownOpen] = useState(false);
	const [businessDownOpen, setBusinessDownOpen] = useState(false);

	let pricing = [
		{ name: 'Pricing', link: '/pricing' },
		{
			name: 'Benefits Calculator',
			link: 'https://www.paua.com/savings-calculator',
			isExternal: true,
		},
	];

	let business = [
		{ name: 'EV Charge Card', link: 'https://www.paua.com/ev-charge-card', isExternal: true },
		{ name: 'Paua Reimburse', link: 'https://www.paua.com/paua-reimburse', isExternal: true },
		{ name: 'How it works', link: 'https://www.paua.com/how-it-works', isExternal: true },
		{
			name: "SME's & Sole Traders",
			link: 'https://www.paua.com/sme-sole-traders',
			isExternal: true,
		},
		{ name: 'Large Fleets', link: 'https://www.paua.com/large-fleets', isExternal: true },
	];

	return (
		<>
			<nav
				className={
					'fixed w-full flex flex-wrap justify-between px-2 pt-2 sm:px-0 lg:pt-0 bg-white z-20 top-0 drop-shadow-xl' +
					(navbarOpen
						? ' bg-paua-deep-purple text-white items-start h-100 pt-4'
						: ' bg-white items-center text-paua-deep-purple ')
				}
			>
				<div className='max-w-[1750px] w-11/12 lg:w-full mx-auto flex flex-wrap items-center justify-around z-30'>
					<div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
						<div>
							{/*
							Pedro Costa 11/09/2024 - this was commented so we can redirect our website's menu logo to the new domain "http://www.paua.com"
							
							{navbarOpen ? (
								<NavLink to='https://www.paua.com/' onClick={() => setNavbarOpen()}>
									<img
										className='flex items-center py-2'
										src='../../images/logos/paua-logo-white.svg'
										width='100'
										alt='paua logo'
									/>
								</NavLink>
							) : (
								<NavLink to='/'>
									<img
										className='py-2 flex items-center'
										src='../../images/logos/paua-logo.svg'
										width='100'
										alt='paua logo'
									/>
								</NavLink>
							)} */}
							{navbarOpen ? (
								<a href='https://www.paua.com/'>
									<img
										className='flex items-center py-2'
										src='../../images/logos/paua-logo-white.svg'
										width='100'
										alt='paua logo'
									/>
								</a>
							) : (
								<a href='https://www.paua.com/'>
									<img
										className='py-2 flex items-center'
										src='../../images/logos/paua-logo.svg'
										width='100'
										alt='paua logo'
									/>
								</a>
							)}
						</div>

						<button
							className='cursor-pointer text-xl leading-none pr-1 lg:px-3 lg:py-1  hover:border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
							type='button'
							onClick={() => setNavbarOpen(!navbarOpen)}
						>
							{navbarOpen ? (
								<img
									alt='close icon'
									src='../../images/icons/close-mobile.png'
									width={18}
								/>
							) : (
								<img
									alt='open mobile menu icon'
									src='../../images/icons/burger.png'
									width={22}
								/>
							)}
						</button>
					</div>
					<div
						className={
							'lg:flex justify-center items-start lg:items-center w-full lg:w-10/12 lg:pl-3' +
							(navbarOpen
								? ' flex flex-shrink bg-paua-deep-purple text-white justify-start pt-5 h-100'
								: ' hidden bg-white')
						}
					>
						<ul className='flex pt-2 lg:pt-0 flex-col h-80 w-10/12  lg:h-auto lg:flex-row justify-around list-none lg:ml-auto text-base lg:text-lg'>
							<li
								onMouseLeave={isMobile ? null : () => setPriceDownOpen(false)}
								className={
									priceDownOpen
										? `nav-item flex-col text-xl  lg:py-2 lg:px-3 lg:text-lg`
										: `nav-item flex-col text-xl lg:py-2 lg:px-3 lg:text-lg`
								}
							>
								<NavLink
									to='#'
									onClick={() => setPriceDownOpen(!priceDownOpen)}
									onMouseEnter={isMobile ? null : () => setPriceDownOpen(true)}
									className='text-white  lg:text-paua-deep-purple font-semibold py-2 flex items-center leading-snug'
								>
									{priceDownOpen ? (
										<div className='flex flex-row justify-center items-center'>
											<div
												className={`text-paua-pink lg:text-paua-deep-purple `}
											>
												Prices
											</div>
											<span className='ml-2 lg:ml-0 lg:hidden'>
												<ArrowUp />
											</span>
										</div>
									) : (
										<div className='flex flex-row justify-center items-center'>
											{(location.pathname === '/pricing') === true ||
											(location.pathname === '/savings-calculator') ===
												true ? (
												<div className={`text-paua-pink lg:text-paua-pink`}>
													Prices
												</div>
											) : (
												<div
													className={`text-paua-white lg:text-paua-deep-purple `}
												>
													Prices
												</div>
											)}
											<div className='lg:hidden ml-2 lg:ml-0'>
												<ArrowDown />
											</div>
										</div>
									)}
								</NavLink>

								{priceDownOpen ? (
									<div className='-ml-3'>
										<NavDropDown
											content={pricing}
											onClick={() => setPriceDownOpen(!priceDownOpen)}
											onMouseLeave={
												isMobile
													? null
													: () => setPriceDownOpen(!priceDownOpen)
											}
											MobileOnClick={() => setNavbarOpen()}
										/>
									</div>
								) : null}
							</li>
							<li className='nav-item text-xl lg:text-lg lg:hover: lg:py-2 lg:px-3'>
								<a
									href='https://www.paua.com/networks'
									className='w-full py-2 flex items-center font-semibold leading-snug'
								>
									Networks
								</a>
							</li>
							<li
								onMouseLeave={isMobile ? null : () => setBusinessDownOpen(false)}
								className={
									businessDownOpen
										? `nav-item flex-col text-xl  lg:py-2 lg:px-3 lg:text-lg`
										: `nav-item flex-col text-xl lg:py-2 lg:px-3 lg:text-lg`
								}
							>
								<NavLink
									to='#'
									onClick={() => setBusinessDownOpen(!businessDownOpen)}
									onMouseEnter={isMobile ? null : () => setBusinessDownOpen(true)}
									className='text-white lg:text-paua-deep-purple font-semibold py-2 flex items-center leading-snug'
								>
									{businessDownOpen ? (
										<div className='flex flex-row justify-center items-center'>
											<div className='text-paua-pink lg:text-paua-deep-purple'>
												Products
											</div>
											<span className='ml-2 lg:ml-0 lg:hidden'>
												<ArrowUp />
											</span>
										</div>
									) : (
										<div className='flex flex-row justify-center items-center'>
											<div
												className={
													(location.pathname === '/how-it-works') ===
														true ||
													(location.pathname === '/fleet-management') ===
														true ||
													(location.pathname === '/sme-soletraders') ===
														true
														? `lg:text-paua-pink`
														: `text-white lg:text-paua-deep-purple`
												}
											>
												Products
											</div>
											<div className='lg:hidden ml-2 lg:ml-0'>
												<ArrowDown />
											</div>
										</div>
									)}
								</NavLink>
								{businessDownOpen ? (
									<div className='-ml-3'>
										<NavDropDown
											content={business}
											onClick={() => setBusinessDownOpen(!businessDownOpen)}
											MobileOnClick={() => setNavbarOpen()}
											onMouseLeave={
												isMobile
													? null
													: () => setBusinessDownOpen(!businessDownOpen)
											}
										/>
									</div>
								) : null}
							</li>
							<li className='nav-item text-xl lg:text-lg  lg:py-2 lg:px-3'>
								<a
									href='https://www.paua.com/news-and-opinion'
									className='w-full py-2 flex items-center font-semibold leading-snug'
								>
									News
								</a>
							</li>
							<li className='nav-item text-xl lg:text-lg  lg:py-2 lg:px-3'>
								<a
									href='https://www.paua.com/about-us'
									className='w-full py-2 flex items-center font-semibold leading-snug'
								>
									About us
								</a>
							</li>

							{isMobile && (
								<div className='w-full flex-col flex justify-center'>
									<div>
										<button
											className='px-3 py-1 mt-10 flex items-center bg-paua-pink h-[48px] rounded-sm w-full leading-snug hover:opacity-75 navbar-get-started'
											id='sign-up-navbar'
											onClick={() =>
												window.open(
													'https://app.pauatech.com/register',
													'_blank'
												)
											}
										>
											<span className='text-white text-center font-semibold text-base xl:text-lg w-full'>
												Get Started
											</span>
										</button>
									</div>

									<div>
										<a
											className='px-3 w-full py-2 flex items-center leading-snug hover:opacity-75'
											href='https://app.pauatech.com/PauaFleet/Login'
										>
											<span className='w-full text-lg mt-4 ml-2 text-white text-center font-semibold underline underline-offset-4'>
												Login
											</span>
										</a>
									</div>
								</div>
							)}
							{isPageWide && (
								<>
									<li className='border-l border-light-gray'>
										<a
											className=' ml-5 py-2 flex items-center leading-snug hover:opacity-75'
											href='https://app.pauatech.com/PauaFleet/Login'
										>
											<span className='text-paua-bright-purple lg:text-lg font-semibold underline underline-offset-4 lg:py-2 lg:px-3'>
												Login
											</span>
										</a>
									</li>
									<li className='nav-item flex items-center'>
										<button
											className=' py-1 flex items-center leading-snug bg-paua-bright-purple h-[36px] 2xl:h-[40px] w-full rounded font-white hover:opacity-75'
											onClick={() =>
												window.open(
													'https://app.pauatech.com/register',
													'_blank'
												)
											}
											id='sign-up-navbar'
										>
											<span className='text-white text-center font-semibold text-base xl:text-lg navbar-get-started'>
												Get Started
											</span>
										</button>
									</li>
								</>
							)}
						</ul>
					</div>
				</div>
				{/* <Banner /> */}
				{/* <BannerPins /> */}
			</nav>
		</>
	);
}
