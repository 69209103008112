import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '../../utils/responsive-query';

export default function NavDropDown(props) {
	let isMobile = useMediaQuery('(max-width: 1023px)');

	return (
		<div
			onClick={props.MobileOnClick}
			onMouseLeave={props.onMouseLeave}
			className='bg-paua-deep-purple lg:bg-white lg:rounded-b-lg lg:pb-3 px-3 lg:px-5 lg:mt-2 -mr-4 flex items-center lg:absolute mx-auto lg:mx-0 text-base'
		>
			{isMobile ? (
				<ul className=' min-h-32 max-h-36 flex flex-col justify-around'>
					{props.content.map((item, i) => {
						return (
							<li
								key={i}
								onClick={props.MobileOnClick}
								onMouseLeave={props.onMouseLeave}
								className='text-base py-1 mb-2'
							>
								<NavLink
									to={item.link}
									className={({ isActive }) =>
										isActive
											? 'text-white  flex items-center leading-snug hover:text-paua-pink'
											: 'text-white lg:text-paua-deep-purple flex items-center leading-snug hover:text-paua-pink'
									}
								>
									{item.name}
								</NavLink>
							</li>
						);
					})}
				</ul>
			) : (
				<ul>
					{props.content.map((item, i) => {
						return (
							<li key={i} onClick={props.onClick} className='lg:text-lg'>
								{item.isExternal ? (
									<a
										href={item.link}
										className='text-white lg:text-paua-deep-purple font-semibold py-2 flex items-center leading-snug hover:underline underline-offset-4'
										target='_blank'
										rel='noreferrer'
									>
										{item.name}
									</a>
								) : (
									<NavLink
										to={item.link}
										className={({ isActive }) =>
											isActive
												? 'text-paua-pink py-2 font-semibold flex items-center leading-snug hover:underline underline-offset-4'
												: 'text-white lg:text-paua-deep-purple font-semibold py-2 flex items-center leading-snug hover:underline underline-offset-4'
										}
									>
										{item.name}
									</NavLink>
								)}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
}
