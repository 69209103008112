import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import ScrollToTop from './components/organisms/header/scroll-to-top-effects';
import { chargepointTotal } from './data/chargepoint-total';
import Header from './components/organisms/header/header';
import { CookiesProvider } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';

const networkTotal = chargepointTotal.total;
const Layout = lazy(() => import('./pages/Layout'));
const Home = lazy(() => import('./pages/home-page'));
const EVChargeCard = lazy(() => import('./pages/ev-charge-card'));
const PauaReimburse = lazy(() => import('./pages/paua-reimburse'));
const ElectricFuelCard = lazy(() => import('./pages/electric-fuel-card'));
const ElectricVehicleFuelCard = lazy(() => import('./pages/electric-vehicle-fuel-card'));
const PricingPackingPage = lazy(() => import('./pages/pricing-package-page'));
const NetworksPage = lazy(() => import('./pages/networks-page'));
const HowItWorksPage = lazy(() => import('./pages/how-it-works-page'));
const NoPage = lazy(() => import('./pages/404'));
const PauaFleetManagementPage = lazy(() => import('./pages/paua-for-fleet-management'));
const PauaSavingsCalculator = lazy(() => import('./pages/paua-savings-calculator'));
const HBNews = lazy(() => import('./pages/hubspot-blog'));

const BlogHubArticle = lazy(() => import('./pages/[blog_hub]'));
const AboutUs = lazy(() => import('./pages/about-us'));
const EnterpriseContactUs = lazy(() => import('./pages/enterprise-contact-form'));
const GeneralContactUs = lazy(() => import('./pages/contact-us-general'));

const AlternativePaymentForm = lazy(() => import('./pages/payment-method-form'));
const SMEandSoleTraderPage = lazy(() => import('./pages/smes-and-sole-traders'));

const CookiePolicy = lazy(() => import('./pages/cookie-policy'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy-paua'));

const DownloadApp = lazy(() => import('./pages/download-app'));

const tagManagerArgs = {
	gtmId: 'GTM-WDJLHCP',
};
TagManager.initialize(tagManagerArgs);

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;
class App extends Component {
	render() {
		return (
			<HelmetProvider>
				<Helmet>
					<title>Paua | Making EV charging easier for business</title>
				</Helmet>
				<div className='App'>
					<Header />
					<Suspense
						fallback={
							<div className='flex justify-center items-center min-h-screen'>
								<div>
									<img
										className='animate-spin h-12 w-12 mx-auto '
										src='/images/icons/spinner.svg'
										alt='loading..'
									/>
									<p className='text-center text-paua-deep-purple font-semibold text-base'>
										Loading...
									</p>
								</div>
							</div>
						}
					>
						<ScrollToTop />
						<IntercomProvider appId={INTERCOM_APP_ID}>
							<CookiesProvider>
								<Routes>
									<Route path='/' element={<Layout />}>
										<Route
											index
											element={<Home networkTotal={networkTotal} />}
										/>
										<Route
											path='ev-charge-card'
											element={<EVChargeCard networkTotal={networkTotal} />}
										/>
										<Route
											path='electric-fuel-card'
											element={
												<ElectricFuelCard networkTotal={networkTotal} />
											}
										/>
										<Route
											path='electric-vehicle-fuel-card'
											element={
												<ElectricVehicleFuelCard
													networkTotal={networkTotal}
												/>
											}
										/>
										<Route path='paua-reimburse' element={<PauaReimburse />} />
										<Route
											path='pricing'
											element={
												<PricingPackingPage networkTotal={networkTotal} />
											}
										/>
										<Route
											path='how-it-works'
											element={<HowItWorksPage networkTotal={networkTotal} />}
										/>
										<Route
											path='networks'
											element={<NetworksPage networkTotal={networkTotal} />}
										/>
										<Route
											path='fleet-management'
											element={
												<PauaFleetManagementPage
													networkTotal={networkTotal}
												/>
											}
										/>
										<Route
											path='sme-soletraders'
											element={
												<SMEandSoleTraderPage networkTotal={networkTotal} />
											}
										/>
										<Route
											path='savings-calculator'
											element={
												<PauaSavingsCalculator
													networkTotal={networkTotal}
												/>
											}
										/>
										<Route path='/news' element={<HBNews />} />
										<Route path='/news/:id' element={<BlogHubArticle />} />
										<Route
											path='alternative-payment-form'
											element={<AlternativePaymentForm />}
										/>
										<Route
											path='enterprise-contact-form'
											element={<EnterpriseContactUs />}
										/>
										<Route path='download-paua' element={<DownloadApp />} />
										<Route path='contact-us' element={<GeneralContactUs />} />
										<Route path='cookie-policy' element={<CookiePolicy />} />
										<Route path='privacy-policy' element={<PrivacyPolicy />} />

										<Route
											path='about-us'
											element={<AboutUs networkTotal={networkTotal} />}
										/>

										<Route path='*' element={<NoPage />} />
									</Route>
								</Routes>
							</CookiesProvider>
						</IntercomProvider>
					</Suspense>
				</div>
			</HelmetProvider>
		);
	}
}

export default App;
